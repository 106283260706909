html {
  background-color: rgb(102, 102, 102);
  font-family: 'Roboto', sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  width: 100vw;
}

.App-header {
  margin-top: 25px;
}

h1 {
  text-align: center;
  text-transform: uppercase;
  font-family: 'Oswald', sans-serif;
  font-size: 72px;
  padding: 0;
  margin: 0;
  color: white;
  text-shadow: 3px 3px 6px rgba(190, 190, 190, 0.37);
}

.languageSelect {
  width: 150px;
  justify-items: end;
  margin: 15px 15px auto auto;
}